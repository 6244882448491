<template>
  <header  class="header">
    <div class="left">
       <NuxtLink :to="'/'">
         <img src="/public/img/logo_h.png" alt="" />
    </NuxtLink>
    </div>
    <div class="right">
      <div class="retrieval">
      </div>
      <div class="btn-list">
        <div class="btn-list_box">
          <NuxtLink to="/vip">
            <el-button class="btn-list-box-btn"><i style="font-size: 20px"  class="iconfont icon-vip1"></i> &nbsp;<span>VIP</span>会员</el-button>
          </NuxtLink>
          <div class="btnLogin">
            <el-button  v-if="!avatarIf && ys_avatarIf" class="logingbtn" @click="getLogin" color="#ED4014">登录/注册</el-button>
            <client-only>
            <el-popover  v-if="avatarIf"
                :disabled="popoverDis"
                placement="bottom"
                :width="80"
                :persistent="false"
                :show-arrow="false"
                popper-class="myhead"
                trigger="hover">
              <div class="personalInfo" >
                <NuxtLink class="link-no-underline" :to="'/personalCenter/id/'+1">
                  <div class="card">
                    <div class="cardIcon"><i class="iconfont icon-zhanghao"></i></div><span>我的账号</span>
                  </div>
                </NuxtLink>
                <NuxtLink class="link-no-underline" :to="'/personalCenter/id/'+3">
                  <div class="card">
                    <div class="cardIcon"><i class="iconfont icon-wodedingdan"></i></div><span>我的订单</span>
                  </div>
                </NuxtLink>
                <NuxtLink class="link-no-underline" :to="'/personalCenter/id/'+4">
                  <div class="card">
                    <div class="cardIcon"><i class="iconfont icon-fapiaoguanli"></i></div><span>我的发票</span>
                  </div>
                </NuxtLink>
                <div class="card" @click="getQuit">
                  <div class="cardIcon"><i class="iconfont icon-tuichudenglu"></i></div><span>退出登录</span>
                </div>
              </div>
              <template #reference>
                <img src="/img/cover_head.png" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="avatar" alt="">
              </template>
            </el-popover>
            </client-only>
          </div>
        </div>
      </div>
    </div>
    <login style="z-index: -1" @login="update" :data="showData" :key="showData"></login>
  </header>
</template>

<script setup lang="ts">
import { useLoginStore } from '@/src/store'
const loginStore = useLoginStore()
const popoverDis = ref(false)
import { ref, reactive } from "vue";
import { getLocal, removeLocal } from "../../src/utils/local";
import Login from "../../src/components/login/login.vue";
import Search from "../../src/components/search/search.vue";

interface INavConfig {
  name: string;
  url: string;
}
const uniqueNumbers = computed(() => [...new Set(historyList.value)]);
const navConfig = reactive<INavConfig[]>([
  // { name: "首页", url: "" },
]);
const router = useRouter();
const avatarIf = ref(false)
const ys_avatarIf = ref(false);
const avatarData = ref()
const showData = ref(0)
const getLogin = () => {
  popoverDis.value = false
  const loginStore = useLoginStore()
  loginStore.openloginstate()
}
//退出登录
const getQuit = (()=>{
  removeLocal("token");
  removeLocal("uid");
  popoverDis.value = true
  getState()
  router.push('/')
});
const getState = ()=>{
  if(getLocal('token')){
    avatarIf.value = true;
    avatarData.value = getLocal('avatar')
  }else{
    avatarIf.value = false;
  }
  ys_avatarIf.value = true;


}

onBeforeMount(() => {
  // getState()
  // showData.value = 0
})

//初始化获取登录状态
onMounted(() => {
  getState()
  showData.value = 0
});


const update = () => {
  getState()
};
//监听是否登录获取状态
watch(() => loginStore.loginshowstate, (newValue, oldValue) => {
  if(newValue===false){
    getState()
  }
})
</script>
<style lang='scss' scoped>
.link-no-underline  {
  text-decoration: none;
}
.ivu-input:focus {
  box-shadow: unset !important;
}

.header {
  justify-content: space-between;
  //box-shadow: 0px 0px 10px 0px rgba(33, 32, 32, 0.25);
  border-bottom: 1px solid #EBEEF5;
  height: 50px;
  z-index: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 1000px;
  .left{
    width: 20%;
    img{
      width: 130px;
      height: 40px;
      object-fit: cover;
      margin-left: 20px;
    }
  }
  .right{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    .retrieval{
    }
  }
    .btn-list {
      .btn-list_box{
        display: flex;
        align-items: center;
        .btnLogin{
          display: flex;
          align-items: center;
          margin-left: 10px;
          width: 100px;
        }
        .btn-list-box-btn{
          background: linear-gradient(90deg,#fceee1,#fcdac1);
          margin-left: 10px;
          color:#6b3d1e;
          border:0;
          height: 36px;
          padding: 0px 10px;
          border-radius: 5px;
          span{
            font-weight: bold;
          }
        }
        .logingbtn{
          height: 36px;
        }
      }
      }
      .avatar{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        cursor: pointer;
      }
      .query-Btn{
        height: 100%;
        background-color: #409EFF;
        color: #FFFFFF;
      }
}

.personalInfo{
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .imageBox{
    border-bottom: 1px solid #cccccc;
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    img{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      object-fit: cover;
    }
    .imgText{
      margin-left: 15px;
      font-size: 14px;
      font-weight: 500;
      color: #3B3946;
      line-height: 20px;
      text-shadow: 0 0 10px rgba(0,0,0,0.14);
    }
  }
  .card:hover{
    background-color: #f8f8f8;
  }
  .card{
    cursor: pointer;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      color: #333 !important;
      font-size: 14px;
      margin-left: 8px;
    }
    .cardIcon{
      font-size: 20px;
      position: relative;
      i{
        position: absolute;
        top: -11px;
        left: -13px;
      }
    }
  }
}
</style>
<style scoped>
:deep(.el-popover.el-popper){
  padding: 0;
}
</style>
